import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import { generateRandomString } from '@/helpers/string.helper';

@Component({
  components: { VueTimepicker }
})
export default class Timepicker extends Vue {
  id = generateRandomString();
  innerValue: string = '';

  @Prop()
  value?: string;

  @Watch('innerValue')
  innerValueChange(newVal: string) {
    this.$emit('input', newVal);
  }

  @Watch('value')
  valueChange(newVal: string) {
    this.innerValue = newVal;
  }

  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
}
