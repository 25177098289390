import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import debounce from 'lodash/debounce';
import isFunction from 'lodash/isFunction';
import { generateRandomString } from '@/helpers/string.helper';

interface BaseAutocompleteType {
  id: number;
  title: string;
  item: any;
}

@Component
export default class BaseAutocomplete extends Vue {
  listId = generateRandomString();
  innerValue = '';
  items: BaseAutocompleteType[] = [];
  isShowList = false;
  isInitialShowList = false;
  tryingToGet = false;

  @Prop({
    required: false,
  })
  value?: string;

  @Prop({
    required: true
  })
  searchCallback!: (searchValue: string) => Promise<any>;

  @Prop({
    required: false
  })
  prepareResultFunc?: (result: any) => any;

  @Prop({
    required: false,
    type: Boolean
  })
  preload?: boolean;

  @Watch('value')
  valueChange(newVal: string, oldVal: string) {
    if (newVal !== oldVal) {
      this.innerValue = newVal === null ? '' : newVal;
    }
  }

  @Watch('innerValue')
  innerValueChange(newVal: string, oldVal: string) {
    if (newVal !== oldVal && newVal === '') {
      this.isShowList = false;
      this.$emit('choose', null);
    }
  }

  created() {
    this.debounceFind = debounce(this.debounceFind, 1500);
    this.hideList = debounce(this.hideList, 200);

    if (this.value) {
      this.innerValue = this.value;
      this.isInitialShowList = true;
      this.find();
    } else if (this.preload) {
      this.isInitialShowList = true;
      this.find();
    }
  }

  debounceFind() {
    this.find();
  }

  find() {
    this.tryingToGet = true;
    this.searchCallback(this.innerValue)
      .then(result => {
        if (result.length) {
          this.items = this.prepareResult(result);
        }

        this.tryingToGet = false;
        this.isShowList = !this.isInitialShowList && result.length;

        if (this.isInitialShowList) {
          this.isInitialShowList = false;
        }
      })
      .catch(() => {
        this.tryingToGet = false;
        this.isShowList = false;
      });
  }

  onInput() {
    this.debounceFind();
  }

  hideList() {
    this.isShowList = false;
  }

  onFocus() {
    if (this.items.length && !this.$attrs.readonly && !this.$attrs.disabled) {
      this.isShowList = true;
    }
  }

  onFocusOut() {
    this.hideList();
  }

  choose(item: BaseAutocompleteType) {
    this.isShowList = false;
    this.innerValue = item.title;
    this.$emit('choose', item);
  }
  
  prepareResult(result: any) {
    if (isFunction(this.prepareResultFunc)) {
      return this.prepareResultFunc(result);
    }
    return result;
  }
}
