import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';

@Component({
  name: 'MultiselectInput',
  components: { Multiselect }
})
export default class MultiselectInput extends Vue {
  @Prop()
  value?: string;

  innerValue: any = null;

  @Watch('innerValue')
  innerValueChange(newVal: string) {
    this.$emit('input', newVal);
  }

  @Watch('value')
  valueChange(newVal: string) {
    this.innerValue = newVal;
  }

  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }

  mounted() {
    const multiselect: any = this.$refs.multiselect;
    if (multiselect) {
      multiselect.$refs.tags.classList.add('shadow-sm', 'border-0', 'form-control', 'form-control-sm');
    }
  }

  getTitle(option) {
    return option?.text;
  }
}
