import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FormBlock extends Vue {
  isOpen = true;

  @Prop({
    required: false,
  })
  openDefault;

  @Prop({
    type: Boolean,
    required: false,
  })
  disableWrap;

  created() {
    if (this.openDefault !== undefined) {
      this.isOpen = this.openDefault;
    }
  }
}
