export const APP_OF = 'of';
export const APP_WAKU = 'waku';

export const getAppTypeLabel = (type: string) => {
  switch (type) {
    case APP_OF: return 'OF';
    case APP_WAKU: return 'WAKU';
    default: return '';
  }
}

export const getAppTypeList = () => [
  { value: APP_OF, text: getAppTypeLabel(APP_OF) },
  { value: APP_WAKU, text: getAppTypeLabel(APP_WAKU) },
]

export const getSocialNetworkList = () => [
  { value: 'facebook', text: 'Facebook' },
  { value: 'google', text: 'Google' },
  { value: 'linkedin', text: 'Linkedin' },
  { value: 'apple', text: 'Apple' },
]
