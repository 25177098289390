import { Component, Prop, Vue } from 'vue-property-decorator';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

@Component({
  name: 'ImageCropperModal',
  components: {
    VueCropper
  }
})
export default class ImageCropperModal extends Vue {
  imgSrc: string = '';
  cropImg: string = '';

  @Prop({
    required: true
  })
  image!: File;

  @Prop({
    required: true
  })
  updateCallback!: (file: File) => void;

  created() {
    const reader = new FileReader();
    reader.onloadend = () => this.imgSrc = reader.result as string;
    reader.readAsDataURL(this.image);
  }

  cropImage() {
    const cropper: any = this.$refs.cropper;
    cropper.getCroppedCanvas().toBlob(blob => {
      const file = new File([blob], `(cropped) ${this.image.name}`, {
        type: this.image.type,
        lastModified: new Date().getTime(),
      });

      this.updateCallback(file);
      this.cancel();
    }, this.image.type);
  }

  cancel() {
    this.$emit('close');
  }
}
