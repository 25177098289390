import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import BTextInputValidation from '@/components/ui/inputs/b-text-input-validation/bTextInputValidation.vue';
import BMarkdownInput from '@/components/ui/inputs/b-markdown-input/bMarkdownInput.vue';
import GoogleMapPicker from '@/components/ui/inputs/google-map-picker/googleMapPicker.vue';
import DatepickerSingle from '@/components/ui/inputs/datepicker-single/datepickerSingle.vue';
import BFileInputValidation from '@/components/ui/inputs/b-file-input-validation/bFileInputValidation.vue';
import BSelectInputValidation from '@/components/ui/inputs/b-select-input-validation/bSelectInputValidation.vue';
import BaseAutocomplete from '@/components/ui/inputs/base-autocomplete/base-autocomplete.vue';
import MultiselectInput from '@/components/ui/inputs/multiselect-input/multiselect-input.vue';
import ImageSlider from '@/components/ui/image-slider/imageSlider.vue';
import { Action, Getter } from 'vuex-class';
import {
  EventsCreateRequest,
  EventsUpdateLocaleRequest,
  EventsUpdateRequest
} from '@/types/request/events-request.types';
import { EventType } from '@/types/api/events/event.type';
import { VenuesRequest } from '@/types/request/venue-request.types';
import { ListResponseType } from '@/types/response/list-response.type';
import { VenueType } from '@/types/api/venue/venue.type';
import { LanguageType } from '@/types/api/info/language.type';
import LocaleUpdater from '@/components/ui/locale-updater/localeUpdater.vue';
import { LocaleUpdateEvent } from '@/components/ui/locale-updater/localeUpdater';
import { EventCard } from '@/store/modules/events/actions';
import { EventLocaleType } from '@/types/api/events/event-locale.type';
import { ImageType } from '@/types/api/image.type';
import { GeopointType } from '@/types/app/geopoint.type';
import VisibleIcon from '@/components/ui/visible-icon/visibleIcon.vue';
import EventSchedule from '@/components/ui/event-schedule/event-schedule.vue';
import { EventScheduleType } from '@/types/api/events/event-schedule.type';
import FormBlock from '@/components/ui/form-block/form-block.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { APP_OF, getAppTypeLabel, getAppTypeList } from '@/constants/app.constants';

const getTitle = () => window.location.href.includes('/event/update')
  ? 'events.updateEvent' : 'events.addEvent';

@Component({
  name: 'EventForm',
  page: {
    title: getTitle()
  },
  components: {
    ValidationObserver,
    BTextInputValidation,
    BMarkdownInput,
    GoogleMapPicker,
    DatepickerSingle,
    BFileInputValidation,
    BSelectInputValidation,
    BaseAutocomplete,
    ImageSlider,
    LocaleUpdater,
    VisibleIcon,
    EventSchedule,
    FormBlock,
    PageTitle,
    MultiselectInput,
  },
})
export default class EventForm extends Vue {
  title = getTitle();
  tryingToUpdate = false;
  tryingToUpdateImages = false;
  image = null;
  name = '';
  urlAddress = '';
  description = '';
  startAt = '';
  endAt = '';
  latitude = '';
  longitude = '';
  locationInfo?: GeopointType;
  images: ImageType[] = [];
  venue: string | null = null;
  venueId: number | null = null;
  initialPointLocation: null | [number, number] = null;
  language = '';
  locales: EventLocaleType[] = [];
  isBlocked = false;
  isOnline = false;
  isVIP = false;
  isCountriesLoading = false;
  isJunketAllow = false;
  featured = false;
  featuredStartAt = '';
  featuredEndAt = '';
  timezone = '';
  schedule: EventScheduleType[] | null = null;
  appMeta: any[] = [{ value: APP_OF, text: getAppTypeLabel(APP_OF) }];
  featuredCountry: any[] = [];

  @Prop({ required: false })
  event?: EventType;

  @Prop({ required: true })
  updateCallback!: (params: EventsCreateRequest | EventsUpdateRequest) => Promise<EventType>;

  @Action('getVenues', { namespace: 'venues' })
  getVenues!: (params: VenuesRequest) => Promise<ListResponseType<VenueType>>;

  @Action('createEventLocale', { namespace: 'events' })
  createEventLocale!: (data: EventCard<EventsUpdateLocaleRequest>) => Promise<EventLocaleType>;

  @Action('updateEventLocale', { namespace: 'events' })
  updateEventLocale!: (data: EventCard<EventsUpdateLocaleRequest>) => Promise<EventLocaleType>;

  @Action('updateImages', { namespace: 'events' })
  updateImages!: (data: EventCard<File[]>) => Promise<EventType>;

  @Action('deleteImage', { namespace: 'events' })
  deleteImage!: (data: EventCard<string>) => Promise<EventType>;

  @Getter('getLanguages', { namespace: 'info' })
  languages?: LanguageType[];

  @Getter('getCountries', { namespace: 'info' })
  countries?: any[];

  created(): void {
    if (this.event) {
      this.images = this.event.images;
      this.name = this.event.name;
      this.description = this.event.description;
      this.startAt = this.event.startAt;
      this.endAt = this.event.endAt;
      this.initialPointLocation = this.event.geopoint ? this.event.geopoint.coordinates : null;
      this.venueId = this.event.venueId;
      this.venue = this.event.venue ? this.event.venue.name : '';
      this.locales = [...this.event.locales];
      this.isBlocked = this.event.isBlocked;
      this.isOnline = this.event.isOnline;
      this.isVIP = this.event.isVIP;
      this.isJunketAllow = this.event.isJunketAllow;
      this.featured = this.event.featured;
      this.featuredStartAt = this.event.featuredStartAt;
      this.featuredEndAt = this.event.featuredEndAt;
      this.timezone = this.event.timezone || '';
      this.schedule = this.event.scheduleEvent;
      this.urlAddress = this.event.urlAddress;
      this.appMeta = this.event.appMeta.map(item => ({ value: item, text: getAppTypeLabel(item) }));
      this.featuredCountry = this.event.featuredCountry.map((item: any) => ({value: item.countryId, text: this.getCountryNameById(item.countryId)}));
    }
  }

  getCountryNameById(id: number): string {
    return this.countries?.find(country => country.id === id)?.name || '';
  }

  onChooseLocation(value: GeopointType) {
    this.latitude = value ? value.lat : '';
    this.longitude = value ? value.lng : '';
    this.locationInfo = value;
  }

  onImageUpload(event) {
    if (this.event) {
      this.onAddImages(event);
    } else {
      this.image = event;
      this.images = [];
    }
  }

  onChoose(value) {
    this.venueId = value ? value.id : null;
  }

  getVenuesRequest(search: string) {
    return this.getVenues({ name: search })
      .then(result => result.items.map(item => ({ id: item.id, title: item.name, item })));
  }

  onApply() {
    if (!this.tryingToUpdate && ((this.latitude && this.longitude) || this.urlAddress)) {
      this.tryingToUpdate = true;
      this.updateCallback({
        timezone: this.timezone,
        isOnline: this.isOnline,
        isVIP: this.isVIP,
        isJunketAllow: this.isJunketAllow,
        featured: this.featured,
        appMeta: this.appMeta.length ? this.appMeta.map(item => item.value) : [APP_OF],
        featuredCountry: this.featuredCountry.length ? this.featuredCountry.map(item => item.value.toString()) : [],
        ...(!this.event ? { name: this.name } : {}),
        ...(!this.event ? { description: this.description } : {}),
        ...(!this.event ? { language: this.language } : {}),
        ...(!this.event ? { image: this.image as any } : {}),
        ...(this.venueId ? { venueId: this.venueId } : {}),
        ...(this.event ? { isBlocked: this.isBlocked as any } : {}),
        ...(this.schedule ? { schedule: this.schedule } : {}),
        ...(this.isOnline ? { urlAddress: this.urlAddress } : {}),
        ...(!this.isOnline ? {
          latitude: this.latitude,
          longitude: this.longitude,
          locationInfo: this.locationInfo,
        } : {}),
        ...(this.isOnline ? { urlAddress: this.urlAddress } : {}),
        ...(this.startAt ? { startAt: this.startAt } : {}),
        ...(this.endAt ? { endAt: this.endAt } : {}),
        ...(this.featuredStartAt ? { featuredStartAt: this.featuredStartAt } : {}),
        ...(this.featuredEndAt ? { featuredEndAt: this.featuredEndAt } : {}),
      })
        .then(() => {
          this.tryingToUpdate = false;
        })
        .catch(() => {
          this.tryingToUpdate = false;
        });
    }
  }

  get languageOptions() {
    return [
      { value: '', text: '---' },
      ...(this.languages || []).map(item => ({ value: item.code, text: item.code.toUpperCase() }))
    ];
  }

  get countryOptions() {
    return [
      ...(this.countries || []).map(item => ({ value: item.id, text: item.name }))
    ]
  }

  get timezoneOptions() {
    return [
      { value: '', text: '---' },
      { value: '-12:00', text: '-12:00' },
      { value: '-11:00', text: '-11:00' },
      { value: '-10:00', text: '-10:00' },
      { value: '-09:00', text: '-09:00' },
      { value: '-08:00', text: '-08:00' },
      { value: '-07:00', text: '-07:00' },
      { value: '-06:00', text: '-06:00' },
      { value: '-05:00', text: '-05:00' },
      { value: '-04:00', text: '-04:00' },
      { value: '-03:30', text: '-03:30' },
      { value: '-03:00', text: '-03:00' },
      { value: '-02:00', text: '-02:00' },
      { value: '-01:00', text: '-01:00' },
      { value: '+00:00', text: '+00:00' },
      { value: '+01:00', text: '+01:00' },
      { value: '+02:00', text: '+02:00' },
      { value: '+03:00', text: '+03:00' },
      { value: '+03:30', text: '+03:30' },
      { value: '+04:00', text: '+04:00' },
      { value: '+05:00', text: '+05:00' },
      { value: '+05:30', text: '+05:30' },
      { value: '+06:00', text: '+06:00' },
      { value: '+07:00', text: '+07:00' },
      { value: '+08:00', text: '+08:00' },
      { value: '+09:00', text: '+09:00' },
      { value: '+09:30', text: '+09:30' },
      { value: '+10:00', text: '+10:00' },
      { value: '+11:00', text: '+11:00' },
      { value: '+12:00', text: '+12:00' },
    ];
  }

  onUpdateLocale(event: LocaleUpdateEvent) {
    const promise = event.locale
      ? this.updateEventLocale({ id: event.locale.id, params: event.editedLocale as any })
      : this.createEventLocale({ id: this.event!.id, params: event.editedLocale as any });

    promise.then(result => {
      this.locales = this.locales.filter(item => item.languageId !== event.language.id);
      this.locales.push(result);
    });
  }

  onDeleteImage(image: ImageType) {
    if (!this.tryingToUpdateImages) {
      this.tryingToUpdateImages = true;
      return this.deleteImage({ id: this.event!.id, params: image.id })
        .then(result => {
          this.images = result.images;
          this.tryingToUpdateImages = false;
        })
        .catch(() => this.tryingToUpdateImages = false);
    }
  }

  onAddImages(images: File[]) {
    if (!this.tryingToUpdateImages) {
      this.tryingToUpdateImages = true;
      return this.updateImages({ id: this.event!.id, params: images })
        .then(result => {
          this.images = result.images;
          this.tryingToUpdateImages = false;
        })
        .catch(() => this.tryingToUpdateImages = false);
    }
  }

  onChangeSchedule(value) {
    this.schedule = value;
  }

  get appTypeOptions() {
    return getAppTypeList();
  }

  startAtUpdate(value) {
    if (value > this.endAt) {
      this.endAt = value;
    }
  }
}
