import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DATE_FORMAT, getDatesArrayInterval } from '@/helpers/date.helper';
import { DateTime } from 'luxon';
import { generateRandomString } from '@/helpers/string.helper';
import { EventScheduleType } from '@/types/api/events/event-schedule.type';
import Timepicker from '@/components/ui/inputs/timepicker/timepicker.vue';

interface EventScheduleDates {
  id: string;
  date: string;
  timeFrom: string;
  timeTo: string;
}

@Component({
  components: {
    Timepicker
  }
})
export default class EventSchedule extends Vue {
  dateRange: EventScheduleDates[] = [];
  isOpen = false;

  @Prop({
    required: true
  })
  startDate!: string;

  @Prop({
    required: true
  })
  endDate!: string;

  @Prop({
    required: false
  })
  schedule?: EventScheduleType[];

  @Watch('startDate')
  startDateChange(newVal: string, oldVal: string) {
    if (newVal !== oldVal) {
      this.init();
    }
  }

  @Watch('endDate')
  endDateChange(newVal: string, oldVal: string) {
    if (newVal !== oldVal) {
      this.init();
    }
  }

  created() {
    this.init();
  }

  init() {
    if (this.startDate && this.endDate && this.endDate >= this.startDate) {
      const startDate = DateTime.fromISO(this.startDate);
      const endDate = DateTime.fromISO(this.endDate);
      const dates = getDatesArrayInterval(startDate, endDate, DATE_FORMAT);

      this.dateRange = dates.map(item => {
        const date = this.schedule && this.schedule.find(sItem => sItem.date === item);
        return {
          id: generateRandomString(),
          date: item,
          timeFrom: date ? date.timeFrom : '00:00:00',
          timeTo: date ? date.timeTo : '00:00:00'
        }
      });
      this.onSelectTime();
    }
  }

  onSelectTime() {
    this.$emit('update:schedule', this.dateRange.map(item => ({
      date: item.date,
      timeFrom: item.timeFrom,
      timeTo: item.timeTo
    })));
  }
}
