import { Component, Vue, Watch } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { Action } from 'vuex-class';
import { EventsUpdateRequest } from '@/types/request/events-request.types';
import { EventType } from '@/types/api/events/event.type';
import { EventCard } from '@/store/modules/events/actions';
import EventForm from '@/components/pages/events/event-form/eventForm.vue';
import { InfoActivityRequest } from '@/types/request/info-request.types';
import { ListResponseType } from '@/types/response/list-response.type';
import { ActivityType } from '@/types/api/info/activity.type';
import { ActivityCard } from '@/store/modules/activity/actions';
import { ActivityCreateRequest } from '@/types/request/activity-request.types';
import { EventActivityType } from '@/types/api/activity/event-activity.type';
import AddActivity from '@/components/ui/modals/addActivity.vue';
import ShowActivitiesWidget from '@/components/ui/show-activities-widget/showActivitiesWidget.vue';
import FormBlock from '@/components/ui/form-block/form-block.vue';
import { AgGridVue } from 'ag-grid-vue';
import { DATE_FORMAT, getDateFromISO } from '@/helpers/date.helper';
import AddOffer from '@/components/ui/modals/addOffer.vue';
import { AgGridHelper } from '@/helpers/ag-grid/ag-grid.helper';
import { IOfferCard } from '@/store/modules/offer/actions';
import { OfferEventRequest } from '@/types/request/offer-request.types';
import { EventOfferType } from '@/types/api/offer/event-offer.type';
import { ICallbackAdditionalParams, ICallbackGeneralParams } from '@/helpers/ag-grid/ag-grid-datasource';
import { ColDef, GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-community';

@Component({
  components: {
    Layout,
    PageTitle,
    EventForm,
    ShowActivitiesWidget,
    FormBlock,
    AgGridVue,
  },
})
export default class EventUpdate extends Vue {
  eventId!: number;
  eventItem: EventType | null = null;
  activities: EventActivityType[] = [];
  agGridOffers: AgGridHelper | null = null;

  @Action('getEvent', { namespace: 'events' })
  getEvent!: (id: number) => Promise<EventType>;

  @Action('updateEvent', { namespace: 'events' })
  updateEvent!: (data: EventCard<EventsUpdateRequest>) => Promise<EventType>;

  @Action('deleteEvent', { namespace: 'events' })
  deleteEvent!: (id: number) => Promise<EventType>;

  @Action('getActivities', { namespace: 'info' })
  getActivities!: (params: InfoActivityRequest) => Promise<ListResponseType<ActivityType>>;

  @Action('activityEventCreate', { namespace: 'activity' })
  activityEventCreate!: (data: ActivityCard<ActivityCreateRequest>) => Promise<EventActivityType>;

  @Action('activityEventDelete', { namespace: 'activity' })
  activityEventDelete!: (id: number) => Promise<EventActivityType>;

  @Action('getOfferEvents', { namespace: 'offer' })
  getOfferEvents!: (data: IOfferCard<OfferEventRequest>) => Promise<ListResponseType<EventOfferType>>;

  @Action('deleteOfferEvent', { namespace: 'offer' })
  deleteOfferEvent!: (id: number) => Promise<EventOfferType>;

  @Watch('currentLang')
  resetGridHeader(newValue: string, oldValue: string) {
    if (this.agGridOffers && newValue !== oldValue) {
      this.agGridOffers.getGridApi()!.refreshHeader();
    }
  }

  created(): void {
    if (this.$route.params.id) {
      this.eventId = Number(this.$route.params.id);
      this.getEvent(this.eventId)
        .then(result => {
          this.eventItem = result;
          this.activities = result.eventActivities || [];
          return result;
        })
        .catch(() => this.goToEvents());
    } else {
      this.goToEvents();
    }
  }

  beforeMount() {
    this.agGridOffers = new AgGridHelper(this.initGridOptionsOptions())
      .enableAutoSizeColumns(false)
      .enableInfinity(this.gridOffersChangeCallback)
      .build();
  }

  goToEvents() {
    this.$router.push({ name: 'events' });
  }

  onApply(params: EventsUpdateRequest) {
    return this.updateEvent({
      id: this.eventId,
      params: params
    })
      .then(() => {
        this.goToEvents();
      });
  }

  onDeleteEvent() {
    this.$modal.show('dialog', {
      title: '',
      text: this.$t('common.deleteQuestion'),
      buttons: [
        {
          title: 'Ok',
          handler: () => {
            this.$modal.hide('dialog');
            this.deleteEvent(this.eventItem!.id)
              .then(() => this.goToEvents());
          }
        },
        {
          title: this.$t('common.cancel')
        }
      ]
    });
  }

  onAddActivity() {
    this.$modal.show(AddActivity, {
      updateCallback: (result: any) => {
        return this.activityEventCreate({ id: this.eventItem!.id, params: result })
          .then(response => this.activities.push(response));
      },
      activitiesAction: (search: string) => this.getActivities({
        take: 20,
        skip: 0,
        name: search
      })
        .then(result => result.items.map(item => ({ id: item.id, title: item.name, item }))),
    }, {
      adaptive: true,
      height: 'auto',
    });
  }

  onDeleteActivity(activity: ActivityType) {
    this.$modal.show('dialog', {
      title: '',
      text: this.$t('common.deleteQuestion'),
      buttons: [
        {
          title: 'Ok',
          handler: () => {
            this.$modal.hide('dialog');
            this.activityEventDelete(activity.id)
              .then(response => {
                this.activities = this.activities.filter(item => item.id !== response.id);
              });
          }
        },
        {
          title: this.$t('common.cancel')
        }
      ]
    });
  }

  gridOffersChangeCallback(general: ICallbackGeneralParams, additional: ICallbackAdditionalParams) {
    additional.api.showLoadingOverlay();

    return this.getOfferEvents({
      id: this.eventId,
      params: {
        take: general.limit,
        skip: general.offset,
      }
    })
      .then(response => {
        additional.api.hideOverlay();
        return {
          result: response.items,
          count: response.paging.totalCount
        }
      });
  }

  initGridOptionsOptions(): GridOptions {
    return {
      columnDefs: this.offersColumnDef,
      enableSorting: false,
      suppressHorizontalScroll: true,
    }
  }

  get offersColumnDef(): ColDef[] {
    return [
      {
        headerValueGetter: () => this.$t('offer.name'),
        field: 'name',
        cellRenderer: 'linkCellRenderer',
        cellRendererParams: {
          url: (params: ICellRendererParams) => params.data && `/offer/${params.data.id}?type=event`,
          text: (params: ICellRendererParams) => params.data && params.data.locales[0]?.name,
        }
      },
      {
        headerValueGetter: () => this.$t('offer.limit'),
        field: 'limit',
      },
      {
        headerValueGetter: () => this.$t('offer.startAt'),
        field: 'startAt',
        valueGetter: (params: ValueGetterParams) => {
          return params.data && getDateFromISO(params.data.startAt, DATE_FORMAT);
        }
      },
      {
        headerValueGetter: () => this.$t('offer.endAt'),
        field: 'endAt',
        valueGetter: (params: ValueGetterParams) => {
          return params.data && getDateFromISO(params.data.endAt, DATE_FORMAT);
        }
      },
      {
        headerValueGetter: () => this.$t('offer.expiresAt'),
        field: 'expiresAt',
        valueGetter: (params: ValueGetterParams) => {
          return params.data && getDateFromISO(params.data.expiresAt, DATE_FORMAT);
        }
      },
      {
        headerName: '',
        field: 'actions',
        minWidth: 110,
        maxWidth: 110,
        width: 110,
        cellRenderer: 'actionsCellRenderer',
        cellRendererParams: {
          buttons: [
            {
              type: 'link',
              actionCallback: this.onDeleteOffer,
              btnContent: '<i class="fe-trash-2 text-danger"></i>',
            },
          ]
        }
      },
    ];
  }

  onAddOffer() {
    this.$modal.show(AddOffer, {
      title: this.$t('offer.addTitle'),
      id: this.eventId,
      isSpot: false,
      updateCallback: () => {
        this.agGridOffers?.refreshInfinity();
      },
    }, {
      adaptive: true,
      height: 'auto',
      width: '80%',
    });
  }

  onDeleteOffer(params: ICellRendererParams) {
    this.$modal.show('dialog', {
      title: '',
      text: this.$t('common.deleteQuestion'),
      buttons: [
        {
          title: 'Ok',
          handler: () => {
            this.$modal.hide('dialog');
            this.deleteOfferEvent(params.data.id)
              .then(() => {
                this.agGridOffers?.refreshInfinity();
              })
          }
        },
        {
          title: this.$t('common.cancel')
        }
      ]
    });
  }
}
