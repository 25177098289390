import { Component, Prop, Vue } from 'vue-property-decorator';
import { LanguageType } from '@/types/api/info/language.type';
import { ValidationObserver } from 'vee-validate';
import BTextInputValidation from '@/components/ui/inputs/b-text-input-validation/bTextInputValidation.vue';
import BMarkdownInput from '@/components/ui/inputs/b-markdown-input/bMarkdownInput.vue';

export interface LocaleUpdateEvent {
  language: LanguageType,
  editedLocale: {
    language: string,
    [key: string]: string
  };
  locale: any;
}

interface Locale {
  languageId: number;
}

@Component({
  components: {
    ValidationObserver,
    BTextInputValidation,
    BMarkdownInput
  }
})
export default class LocaleUpdater extends Vue {
  tryingToUpdate = false;
  selectedLanguage: LanguageType | null = null;
  editedLocale: { [key: string]: string } = {};

  @Prop({
    required: true,
    type: Array
  })
  languages!: LanguageType[];

  @Prop({
    required: true,
    type: Array
  })
  locales!: Locale[];

  created(): void {
    this.selectedLanguage = this.languages[0];
  }

  mounted(): void {
    this.prepareEditedLocale();
  }

  prepareEditedLocale() {
    const locale = this.locales.find(item => item.languageId === this.selectedLanguage!.id);

    this.editedLocale.language = this.selectedLanguage!.code;
    Object.keys(this.$scopedSlots).forEach(attr => {
      this.editedLocale[attr] = locale ? locale[attr] : '';
    });
  }

  onApply() {
    this.$emit('onApply', {
      language: this.selectedLanguage,
      editedLocale: this.editedLocale,
      locale: this.locales.find(item => item.languageId === this.selectedLanguage!.id)
    });
  }

  onClickTab(languageId: number) {
    this.selectedLanguage = this.languages.find(item => item.id === languageId) || null;
    this.prepareEditedLocale();
  }

  isFilled(languageId: number) {
    const locale = this.locales.find(item => item.languageId === languageId);
    return Object.keys(this.$scopedSlots).some(attr => locale && locale[attr]);
  }
}
