<template>
  <div class="ui-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ title }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="cancel">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body ui-modal-body">
        <AddOfferModal
          :id="id"
          :entity="entity"
          :isSpot="isSpot"
          v-on:update="onApply"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import AddOfferModal from '@/components/features/modals/add-offer-modal/add-offer-modal.vue';
  import isFunction from 'lodash/isFunction';

  export default {
    components: {
      AddOfferModal
    },
    props: {
      title: {
        required: true
      },
      isSpot: {
        type: Boolean,
        required: true
      },
      id: {
        required: true
      },
      entity: {
        required: false
      },
      updateCallback: {
        required: true
      },
    },
    data: function() {
      return {};
    },
    methods: {
      onApply: function (value) {
        if (isFunction(this.updateCallback)) {
          this.updateCallback(value);
          this.cancel();
        }
      },
      cancel: function () {
        this.$emit('close');
      }
    }
  }
</script>

<style lang="scss" scoped>
.ui-modal {
  .ui-modal-body {
    max-height: 800px;
    overflow: auto;
  }
}
</style>
