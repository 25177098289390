import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import BTextInputValidation from '@/components/ui/inputs/b-text-input-validation/bTextInputValidation.vue';
import BSelectInputValidation from '@/components/ui/inputs/b-select-input-validation/bSelectInputValidation.vue';
import BMarkdownInput from '@/components/ui/inputs/b-markdown-input/bMarkdownInput.vue';
import {
  OfferCreateEventRequest,
  OfferCreateSpotRequest, OfferUpdateEventRequest,
  OfferUpdateLocaleRequest, OfferUpdateSpotRequest
} from '@/types/request/offer-request.types';
import { SpotOfferType } from '@/types/api/offer/spot-offer.type';
import { EventOfferType } from '@/types/api/offer/event-offer.type';
import VisibleIcon from '@/components/ui/visible-icon/visibleIcon.vue';
import { LanguageType } from '@/types/api/info/language.type';
import DatepickerSingle from '@/components/ui/inputs/datepicker-single/datepickerSingle.vue';
import { ValidationObserver } from 'vee-validate';
import LocaleUpdater from '@/components/ui/locale-updater/localeUpdater.vue';
import { LocaleUpdateEvent } from '@/components/ui/locale-updater/localeUpdater';
import { SpotOfferLocaleType } from '@/types/api/offer/spot-offer-locale.type';
import { EventOfferLocaleType } from '@/types/api/offer/event-offer-locale.type';
import { IOfferCard } from '@/store/modules/offer/actions';

@Component({
  name: 'AddOfferModal',
  components: {
    BTextInputValidation,
    BSelectInputValidation,
    BMarkdownInput,
    VisibleIcon,
    DatepickerSingle,
    ValidationObserver,
    LocaleUpdater,
  },
})
export default class AddOfferModal extends Vue {
  tryingToUpdate = false;
  language = '';
  name = '';
  description = '';
  limit = '';
  expiresAt = '';
  locales: SpotOfferLocaleType[] | EventOfferLocaleType[] | any = [];

  @Prop({
    type: Boolean,
    required: true,
    default: () => true
  })
  isSpot;

  @Prop({ required: true })
  id;

  @Prop({ required: false })
  entity?: SpotOfferType | EventOfferType;

  @Action('createOfferSpot', { namespace: 'offer' })
  createOfferSpot!: (params: OfferCreateSpotRequest) => Promise<SpotOfferType>;

  @Action('updateOfferSpot', { namespace: 'offer' })
  updateOfferSpot!: (data: IOfferCard<OfferUpdateSpotRequest>) => Promise<SpotOfferType>;

  @Action('createOfferEvent', { namespace: 'offer' })
  createOfferEvent!: (params: OfferCreateEventRequest) => Promise<EventOfferType>;

  @Action('updateOfferEvent', { namespace: 'offer' })
  updateOfferEvent!: (data: IOfferCard<OfferUpdateEventRequest>) => Promise<EventOfferType>;

  @Action('createOfferSpotLocale', { namespace: 'offer' })
  createOfferSpotLocale!: (data: IOfferCard<OfferUpdateLocaleRequest>) => Promise<SpotOfferLocaleType>;

  @Action('updateOfferSpotLocale', { namespace: 'offer' })
  updateOfferSpotLocale!: (data: IOfferCard<OfferUpdateLocaleRequest>) => Promise<SpotOfferLocaleType>;

  @Action('createOfferEventLocale', { namespace: 'offer' })
  createOfferEventLocale!: (data: IOfferCard<OfferUpdateLocaleRequest>) => Promise<EventOfferLocaleType>;

  @Action('updateOfferEventLocale', { namespace: 'offer' })
  updateOfferEventLocale!: (data: IOfferCard<OfferUpdateLocaleRequest>) => Promise<EventOfferLocaleType>;

  @Getter('getLanguages', { namespace: 'info' })
  languages?: LanguageType[];

  created() {
    if (this.entity) {
      this.limit = this.entity.limit;
      this.expiresAt = this.entity.expiresAt;
      this.locales = [...this.entity.locales];
    }
  }

  get languageOptions() {
    return [
      { value: '', text: '---' },
      ...(this.languages || []).map(item => ({ value: item.code, text: item.code.toUpperCase() }))
    ];
  }

  onApply() {
    if (this.entity) {
      this.update();
    } else {
      this.create();
    }
  }

  create() {
    if (!this.tryingToUpdate) {
      this.tryingToUpdate = true;
      const action: any = this.isSpot ? this.createOfferSpot : this.createOfferEvent;
      action({
        language: this.language,
        name: this.name,
        description: this.description,
        limit: this.limit.toString(),
        expiresAt: this.expiresAt,
        ...(this.isSpot ? { spotId: this.id.toString() } : { eventId: this.id.toString() })
      })
        .then(response => {
          this.tryingToUpdate = false;
          this.$emit('update', response);
        })
        .catch(() => {
          this.tryingToUpdate = false;
        });
    }
  }

  update() {
    if (!this.tryingToUpdate) {
      this.tryingToUpdate = true;
      const action: any = this.isSpot ? this.updateOfferSpot : this.updateOfferEvent;
      action({
        id: this.id,
        params: {
          limit: this.limit.toString(),
          expiresAt: this.expiresAt,
        }
      })
        .then(response => {
          this.tryingToUpdate = false;
          this.$emit('update', response);
        })
        .catch(() => {
          this.tryingToUpdate = false;
        });
    }
  }

  onUpdateLocale(event: LocaleUpdateEvent) {
    const updateLocale = this.isSpot ? this.updateOfferSpotLocale : this.updateOfferEventLocale;
    const createLocale = this.isSpot ? this.createOfferSpotLocale : this.createOfferEventLocale;
    const promise: Promise<any> = event.locale
      ? updateLocale({ id: event.locale.id, params: event.editedLocale as any })
      : createLocale({ id: this.entity!.id, params: event.editedLocale as any });

    promise.then(result => {
      this.locales = this.locales.filter(item => item.languageId !== event.language.id);
      this.locales.push(result);
    });
  }
}
