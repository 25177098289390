import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

@Component({
  components: { ValidationProvider }
})
export default class BFileInputValidation extends Vue {
  @Prop({ type: String })
  vid?: string;

  @Prop({ type: [Object, String], default: '' })
  rules?: string;

  file: File | File[] | null = null;
}
