import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

@Component({
  components: { ValidationProvider }
})
export default class BTextInputValidation extends Vue {
  @Prop({ type: String })
  vid?: string;

  @Prop({ type: [Object, String], default: '' })
  rules?: string;

  @Prop()
  value?: string;

  innerValue: string | number = '';

  @Watch('innerValue')
  innerValueChange(newVal: string) {
    this.$emit('input', newVal);
  }

  @Watch('value')
  valueChange(newVal: string) {
    this.innerValue = newVal;
  }

  created() {
    const isTypeNumber = this.$attrs.type && this.$attrs.type === 'number';

    if (this.value || isTypeNumber) {
      this.innerValue = this.value || 0;
    }
  }
}
