import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Editor } from '@toast-ui/vue-editor';

@Component({
  components: { Editor }
})
export default class BMarkdownInput extends Vue {
  innerValue: string | number = '';
  options = {
    linkAttribute: {
      target: '_blank',
      contenteditable: 'false',
      rel: 'noopener noreferrer'
    },
    hideModeSwitch: true
  };

  @Prop()
  value?: string;

  @Watch('innerValue')
  innerValueChange(newVal: string) {
    this.$emit('input', newVal);
  }

  @Watch('value')
  valueChange(newVal: string) {
    this.innerValue = newVal;
  }

  created(): void {
    if (this.value) {
      this.innerValue = this.value;
    }
  }

  onEditorChange({ source }) {
    const eventType = source === 'markdown' ? 'getMarkdown' : 'getHtml';
    const result = (this.$refs.markdownEditor as any).invoke(eventType);

    this.$emit('input', result);
  }
}
