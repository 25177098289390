import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import BaseAutocomplete from '@/components/ui/inputs/base-autocomplete/base-autocomplete.vue';
import { generateRandomString } from '@/helpers/string.helper';

@Component({
  components: {
    BaseAutocomplete
  }
})
export default class GoogleMapPicker extends Vue {
  googleMap: any;
  map: any;
  initialZoom = 3;
  initialCenter = { lat: 55.7504461, lng: 37.6174943 };
  innerValue: string | number = '';
  place: any = null;
  isInfoOpen = true;
  infoOptions = {
    content: '',
    pixelOffset: {
      width: 0,
      height: -35
    }
  };

  // [lon, lat]
  @Prop()
  value?: [number | string, number | string];

  @Watch('innerValue')
  innerValueChange(newVal: string) {
    this.$emit('input', newVal);
  }

  @Watch('value')
  valueChange(newVal: string) {
    if (newVal && Array.isArray(newVal)) {
      this.autoSearch(Number(newVal[1]), Number(newVal[0]));
    }
  }

  mounted(): void {
    this.map = this.$refs.map;
    this.map.$mapPromise.then(() => {
      this.googleMap = (window as any).google;
      if (this.value) {
        this.autoSearch(Number(this.value[1]), Number(this.value[0]));
      }
    })
  }

  getLocations(searchValue: string) {
    return new Promise(resolve => {
      const geocoder = new this.googleMap.maps.Geocoder();
      geocoder.geocode(
        { address: searchValue },
        (results, status) => status === 'OK' ? resolve(results) : resolve([])
      );
    });
  }

  prepareResultFunc(result) {
    return result.map(item => ({
      id: generateRandomString(),
      title: item.formatted_address,
      item
    }));
  }

  onChooseLocation(event) {
    if (event) {
      this.setPlace(event.item);
      this.map.$mapObject.setZoom(14);
    } else {
      this.place = null;
      this.emitPlace(null);
      this.map.$mapObject.setZoom(this.initialZoom);
      this.map.$mapObject.setCenter(this.initialCenter);
    }
  }

  setPlace(place) {
    if (place) {
      this.place = place;
      this.innerValue = place.formatted_address;
      this.infoOptions.content = place.formatted_address;
      this.map.panTo(this.getLatLng(place));
      this.emitPlace(place);
    }
  }

  emitPlace(place) {
    const getAddressComponent = (type: string) => {
      const component = (place?.address_components || []).filter(item => item.types?.[0] === type);
      return component[0] ? {
        longName: component[0].long_name,
        shortName: component[0].short_name,
      } : null
    }

    const data = place ? {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      address: place.formatted_address,
      country: getAddressComponent('country'),
      locality: getAddressComponent('locality'),
      route: getAddressComponent('route'),
      streetNumber: getAddressComponent('street_number'),
      postalCode: getAddressComponent('postal_code'),
      plusCode: getAddressComponent('plus_code'),
    } : place;
    this.$emit('choose', data);
  }

  get placePosition() {
    return this.place ? this.getLatLng(this.place) : this.initialCenter;
  }

  getLatLng(place) {
    return {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
  }

  onMapClick(event) {
    this.searchByCoordinates(event.latLng.lat(), event.latLng.lng());
  }

  searchByCoordinates(lat: number, lng: number) {
    const geocoder = new this.googleMap.maps.Geocoder();
    geocoder.geocode({
      location: { lat, lng },
    }, (results, status) => {
      if (status === 'OK' && results[0]) {
        this.setPlace(results[0]);
      }
    });
  }

  autoSearch(lat: number, lng: number) {
    this.searchByCoordinates(lat, lng);
    this.map.$mapObject.setZoom(14);
  }
}
