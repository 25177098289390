<template>
  <svg
    v-on="$listeners"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18" cy="18" r="17.5" transform="rotate(-180 18 18)" stroke="#F0933D"/>
    <path d="M21 12L15 18L21 24" stroke="#F0933D"/>
  </svg>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'SliderLeftIcon'
})
export default class SliderLeftIcon extends Vue {}
</script>

<style lang="scss" scoped></style>
