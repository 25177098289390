import { Component, Prop, Vue } from 'vue-property-decorator';
import { ImageType } from '@/types/api/image.type';
import { Carousel, Slide } from 'vue-carousel';
import SliderLeftIcon from './icons/slider-left-icon.vue';
import SliderRightIcon from './icons/slider-right-icon.vue';
import SliderTrashIcon from './icons/slider-trash-icon.vue';
import BFileInputValidation from '@/components/ui/inputs/b-file-input-validation/bFileInputValidation.vue';
import ImageCropperModal from '@/components/features/modals/image-cropper-modal/image-cropper-modal.vue';
import { isMobile, MODAL_CONFIG } from '@/helpers/app.helper';

@Component({
  components: {
    Carousel,
    Slide,
    SliderLeftIcon,
    SliderRightIcon,
    SliderTrashIcon,
    BFileInputValidation,
  }
})
export default class ImageSlider extends Vue {
  files: File[] = [];

  @Prop({
    required: true,
    type: Array
  })
  images!: ImageType[];

  @Prop({
    required: false,
    type: Boolean,
    default: () => false
  })
  isUpdate?: boolean;

  onDeleteImage(image: ImageType) {
    this.$modal.show('dialog', {
      title: '',
      text: this.$t('common.deleteQuestion'),
      buttons: [
        {
          title: 'Ok',
          handler: () => {
            this.$modal.hide('dialog');
            this.$emit('delete', image);
          }
        },
        {
          title: this.$t('common.cancel')
        }
      ]
    });
  }

  nextSlide() {
    const carousel = this.$refs.carousel as any;
    carousel.goToPage(carousel.getNextPage());
  }

  prevSlide() {
    const carousel = this.$refs.carousel as any;
    carousel.goToPage(carousel.getPreviousPage());
  }

  openCropModal(file: File, index: number) {
    this.$modal.show(ImageCropperModal, {
      updateCallback: (result: File) => {
        this.files = this.files.map((item, idx) => idx === index ? result : item);
        if (!this.isUpdate) {
          this.emitFiles();
        }
      },
      image: file
    }, {
      adaptive: true,
      height: '700px',
      width: '90%',
    });
  }

  onUpload(files: File[]) {
    this.files = files;
    if (!this.isUpdate) {
      this.emitFiles();
    }
  }

  emitFiles() {
    this.$emit('upload', this.files);
  }
}
