<template>
  <div class="ui-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ $t('activity.add') }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="cancel">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <ValidationObserver v-slot="{ invalid, passes }">
        <b-form @submit.prevent="passes(onApply)">
          <div class="modal-body ui-modal-body">
            <BaseAutocomplete
              :label="$t('activity.name')"
              label-for="name"
              :searchCallback="activitiesAction"
              v-on:choose="onChoose"
              preload
              required
            ></BaseAutocomplete>

            <BTextInputValidation
              :label="$t('activity.minBet')"
              label-for="minBet"
              vid="minBet"
              name="minBet"
              v-model="minBet"
              rules="numeric"
            ></BTextInputValidation>

            <BTextInputValidation
              :label="$t('activity.maxBet')"
              label-for="minBet"
              vid="maxBet"
              name="maxBet"
              v-model="maxBet"
              rules="numeric"
            ></BTextInputValidation>

            <BTextInputValidation
              :label="$t('activity.extra')"
              label-for="extra"
              vid="extra"
              name="extra"
              v-model="extra"
            ></BTextInputValidation>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="cancel"
            >
              {{ $t('common.cancel') }}
            </button>
            <button type="submit" class="btn btn-primary" :disabled="tryingToUpdate || invalid || !name">
              <b-spinner small v-if="tryingToUpdate"></b-spinner>
              {{ $t('common.save') }}
            </button>
          </div>
        </b-form>
      </ValidationObserver>

    </div>
  </div>
</template>

<script>
  import isFunction from 'lodash/isFunction';
  import { ValidationObserver } from 'vee-validate';
  import BTextInputValidation from '@/components/ui/inputs/b-text-input-validation/bTextInputValidation.vue';
  import BaseAutocomplete from '@/components/ui/inputs/base-autocomplete/base-autocomplete.vue';

  export default {
    components: {
      ValidationObserver,
      BTextInputValidation,
      BaseAutocomplete
    },
    props: {
      updateCallback: {
        required: true
      },
      activitiesAction: {
        required: true
      }
    },
    data: function() {
      return {
        tryingToUpdate: false,
        name: '',
        minBet: 0,
        maxBet: 0,
        extra: ''
      };
    },
    methods: {
      onApply: function () {
        if (isFunction(this.updateCallback) && !this.tryingToUpdate && this.name) {
          this.tryingToUpdate = true;
          this.updateCallback({
            name: this.name,
            ...(this.minBet ? { minBet: Number(this.minBet) } : {}),
            ...(this.maxBet ? { maxBet: Number(this.maxBet) } : {}),
            ...(this.extra ? { extra: this.extra } : {})
          })
            .then(() => {
              this.tryingToUpdate = false;
              this.cancel();
            })
            .catch(() => {
              this.tryingToUpdate = false;
            });
        }
      },
      cancel: function () {
        this.$emit('close');
      },
      onChoose: function (value) {
        this.name = value ? value.item.name : null;
      }
    }
  }
</script>

<style lang="scss" scoped></style>
