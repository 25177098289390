<template>
  <svg
    v-on="$listeners"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18" cy="18" r="18" fill="#EE5C27"/>
    <path d="M20.7216 14.6084C20.4668 14.6084 20.2603 14.8286 20.2603 15.1002V24.3953C20.2603 24.6667 20.4668 24.8871 20.7216 24.8871C20.9764 24.8871 21.183 24.6667 21.183 24.3953V15.1002C21.183 14.8286 20.9764 14.6084 20.7216 14.6084Z" fill="white"/>
    <path d="M15.2783 14.6084C15.0234 14.6084 14.8169 14.8286 14.8169 15.1002V24.3953C14.8169 24.6667 15.0234 24.8871 15.2783 24.8871C15.5331 24.8871 15.7396 24.6667 15.7396 24.3953V15.1002C15.7396 14.8286 15.5331 14.6084 15.2783 14.6084Z" fill="white"/>
    <path d="M11.3103 13.2519V25.3689C11.3103 26.085 11.5567 26.7576 11.987 27.2402C12.4154 27.7241 13.0116 27.9988 13.6355 28H22.3645C22.9886 27.9988 23.5848 27.7241 24.013 27.2402C24.4433 26.7576 24.6897 26.085 24.6897 25.3689V13.2519C25.5452 13.0098 26.0995 12.1288 25.9851 11.1931C25.8705 10.2575 25.1227 9.55763 24.2375 9.55743H21.8754V8.94268C21.8781 8.42571 21.6863 7.9293 21.343 7.5641C20.9997 7.19909 20.5333 6.99584 20.0484 7.00006H15.9516C15.4667 6.99584 15.0003 7.19909 14.657 7.5641C14.3137 7.9293 14.1219 8.42571 14.1246 8.94268V9.55743H11.7625C10.8773 9.55763 10.1295 10.2575 10.0149 11.1931C9.90049 12.1288 10.4548 13.0098 11.3103 13.2519ZM22.3645 27.0164H13.6355C12.8467 27.0164 12.233 26.2941 12.233 25.3689V13.2951H23.767V25.3689C23.767 26.2941 23.1533 27.0164 22.3645 27.0164ZM15.0473 8.94268C15.0443 8.6866 15.1387 8.44012 15.3092 8.25935C15.4795 8.07857 15.7112 7.97925 15.9516 7.98367H20.0484C20.2888 7.97925 20.5205 8.07857 20.6908 8.25935C20.8613 8.43993 20.9557 8.6866 20.9527 8.94268V9.55743H15.0473V8.94268ZM11.7625 10.541H24.2375C24.6962 10.541 25.0679 10.9374 25.0679 11.4263C25.0679 11.9152 24.6962 12.3115 24.2375 12.3115H11.7625C11.3038 12.3115 10.9321 11.9152 10.9321 11.4263C10.9321 10.9374 11.3038 10.541 11.7625 10.541Z" fill="white"/>
    <path d="M17.9999 14.6084C17.7451 14.6084 17.5386 14.8286 17.5386 15.1002V24.3953C17.5386 24.6667 17.7451 24.8871 17.9999 24.8871C18.2548 24.8871 18.4613 24.6667 18.4613 24.3953V15.1002C18.4613 14.8286 18.2548 14.6084 17.9999 14.6084Z" fill="white"/>
  </svg>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'SliderTrashIcon'
})
export default class SliderTrashIcon extends Vue {}
</script>

<style lang="scss" scoped></style>
