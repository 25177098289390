import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DateRangePicker from 'vue2-daterange-picker';
import { DateTime } from 'luxon';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@/helpers/date.helper';
import { Getter } from 'vuex-class';
import isFunction from 'lodash/isFunction';

export interface DateRangeValues {
  startDate: null | string | Date;
  endDate: null | string | Date;
}

@Component({
  components: { DateRangePicker }
})
export default class DatepickerSingle extends Vue {
  dateRange: DateRangeValues = {
    startDate: null,
    endDate: null,
  };
  localeData: any;

  @Prop({
    type: String,
    default: null
  })
  date?;

  @Prop({
    type: String,
    default: null
  })
  size?;

  @Prop({
    type: Boolean,
    required: false
  })
  withZone?;

  @Getter('getCurrentLang', { namespace: 'lang' })
  currentLang!: string;

  @Watch('currentLang')
  changeDatepickerLocale(newValue: string, oldValue: string) {
    if (newValue !== oldValue) {
      this.initLocaleData();
      this.$forceUpdate();
    }
  }

  @Watch('date')
  startDateChange(newVal: string, oldVal: string) {
    if (newVal !== oldVal) {
      this.dateRange.startDate = newVal;
      this.dateRange.endDate = newVal;
    }
  }

  created() {
    this.dateRange.startDate = this.date || null;
    this.dateRange.endDate = this.date || null;
    this.initLocaleData();
  }

  updateDateValues(dateRange: DateRangeValues) {
    const date = this.dateToISO(dateRange.startDate);
    this.$emit('update:date', date);

    if (this.$listeners.update && isFunction(this.$listeners.update)) {
      this.$listeners.update(date);
    }
  }

  dateFormatter(value) {
    const format = Object.prototype.hasOwnProperty.call(this.$attrs, 'time-picker') ? DATE_TIME_FORMAT : DATE_FORMAT;
    return value ? DateTime.fromJSDate(value).toFormat(format) : '';
  }

  dateToISO(value) {
    return value
      ? DateTime.fromJSDate(value).set({ hour: 0, minute: 0 }).toISO({ includeOffset: this.withZone })
      : '';
  }

  initLocaleData() {
    this.localeData = {
      firstDay: 1,
      format: 'yyyy-mm-dd',
      applyLabel: this.$t('datePicker.apply'),
      cancelLabel: this.$t('datePicker.cancel'),
      weekLabel: this.$t('datePicker.w'),
      customRangeLabel: this.$t('datePicker.customRange'),
      daysOfWeek: [
        this.$t('datePicker.sun'),
        this.$t('datePicker.mon'),
        this.$t('datePicker.tue'),
        this.$t('datePicker.wed'),
        this.$t('datePicker.thu'),
        this.$t('datePicker.fri'),
        this.$t('datePicker.sat')
      ],
      monthNames: [
        this.$t('datePicker.jan'),
        this.$t('datePicker.feb'),
        this.$t('datePicker.mar'),
        this.$t('datePicker.apr'),
        this.$t('datePicker.may'),
        this.$t('datePicker.jun'),
        this.$t('datePicker.jul'),
        this.$t('datePicker.aug'),
        this.$t('datePicker.sep'),
        this.$t('datePicker.oct'),
        this.$t('datePicker.nov'),
        this.$t('datePicker.dec'),
      ],
    };
  }

  get classes() {
    return {
      [`form-control-${this.size}`]: this.size,
    };
  }

  clearValue(event) {
    event.stopPropagation();
    this.dateRange = {
      endDate: null,
      startDate: null
    };
    this.updateDateValues(this.dateRange);
  }
}
